@import 'bulma/sass/utilities/derived-variables.sass';

.container {
  border-left: 1px solid $grey-light;
}

.element {
  min-width: calc(100% / 7);
  border-bottom: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
}

.date_text_field {
  height: 1.5em !important;
  padding: 1em 0.5em !important;
}

.content {
  height: 80px;
  overflow-y: auto;
}
